@import "../public/assets/style/variables.scss";


// .subreseller{
//     .svg {
//     &:hover {
//       fill: $active-color !important;
//     }
//   }
// }

.phone-inp-container{
  display: flex;
  flex-direction: column;

  .phone-label{
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .phone-input{
    width: 100%;
      padding: 4px 11px;
      color: rgba(0,0,0,.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all .3s;
      height: 32px;
  }
}


.ant-item {
    a {
      color: $link-color !important;
    }
  }
  

  .ant-menu-item-selected {
    span {
      a {
        color: $active-color !important;
      }
    }
  }
  
  
  