@import "/public/assets/style/variables";

.dashboard-title {
  font-weight: 700;
  font-size: 19px;
}

.reseller-statistics-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-bottom: 20px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      color: #fff;
      margin-bottom: 0;
    }
  }
}
