@import "/public/assets/style/variables";

.title {
  color: #fff;
  margin-bottom: 0;
}
 
.card-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.referral-statistics-cards{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-subtite{
  font-weight: 700;
  font-size: 19px;
}