@import "/public/assets/style/variables";

.sidebar {
  width: 100%;
  height: 100vh;
  position: relative;
  // background: $app-color;

  .menu {
    height: 500px;
    background: transparent;
    .referral-icon {
      transform: rotate(45deg);
    }
  }

  .sidebar-logo {
    min-height: 120px;
    @include display-center(flex, center, center);
    cursor: pointer;

    img {
      padding: 20px 0 0 0;
      margin-bottom: 40px;
      height: auto;
    }
  }

  .sidebar-button-block {
    width: 90%;
    position: absolute;
    top: 93%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .sidebar-button-block-text {
      font-size: 12px;
      color: $color;
    }

    .sidebar-button-descr {
      height: 30px;
      padding: 0 5px 0 5px;
      @include display-center(flex, center, center);
    }

    .sidebar-link-block {
      height: 35px;
      border-radius: 3px;
      @include display-center(flex, center, center);
      //background-color: $primary-color;
      padding: 0 15px;

      .sidebar-link-icon {
        font-size: 14px;
        color: $color;
      }
    }

    .close {
      display: none;
    }

    .sidebar-button-descr p:nth-child(1) {
      text-align: center;
      color: $color;
      font-size: 11.6px;
      white-space: nowrap;
      overflow: hidden;
      width: 170px;
      animation: type 0.2s steps(50, end) forwards;
    }

    a {
      margin-left: 5px;
      margin-bottom: 3px;
      display: inline-block;
      color: $color;
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // width: 120px;
      animation: link-type 0.2s steps(50, end) forwards;
    }

    @keyframes type {
      0% {
        width: 0;
      }

      100% {
        border-right: none;
      }
    }

    @keyframes link-type {
      0% {
        width: 0;
      }

      100% {
        border-right: none;
      }
    }
  }
}

.ant-item {
  a {
    color: $link-color !important;
  }
}



.ant-menu-item-selected {
  span {
    a {
      color: $active-color !important;
    }
  }
}

// svg {
//   fill: $link-color !important;
// }

// .ant-menu-item .ant-menu-item-icon, .ant-menu-item .anticon{

// }
.sidebar-subreseller-icon{
  svg{
    fill: $link-color !important;

  }
}

.ant-menu-item {
  .anticon {
    color: $link-color !important;
  }
}

.ant-menu-item-selected {
  .anticon {
    color: $active-color !important;
  }

  .sidebar-subreseller-icon{
    svg{
      fill: $active-color !important;
  
    }
  }
}

// :global .ant-menu {
//   //span {
//   //  color: $color;
//   //}
//   //a {
//   //  color: $color;
//   //}

//   // .subreseller-icon {
//   //   svg {
//   //     fill: $active-color !important;

//   //     &:hover {
//   //       fill: $active-color !important;
//   //     }
//   //   }
//   // }
//   .ant-menu-item {
//     &:active {
//       .ant-menu-item-icon {
//         fill: $active-color !important;

//         &:hover {
//           fill: $active-color !important;
//         }
//       }
//     }

//     &:hover {
//       .ant-menu-item-icon {
//         fill: $active-color !important;

//         &:hover {
//           fill: $active-color !important;
//         }
//       }
//     }
//   }

//   .ant-menu-item:hover {
//     .ant-menu-item-icon {
//       color: $active-color !important;
//     }

//     span a:hover {
//       color: $active-color !important;
//     }
//   }

//   .ant-menu-item-selected span {
//     color: $active-color !important;
//     a {
//       color: $active-color !important;
//     }
//   }

//   .ant-menu-item-selected {
//     svg {
//       fill: $active-color !important;
//     }
//   }
//   .ant-menu-item {
//     &:hover {
//       svg {
//         fill: $active-color !important;
//       }
//     }
//   }
//   .ant-menu-item:active {
//     background-color: $app-color;
//   }
// }

.res-ref-version {
  color: $color;
  font-size: 10px;
}

.ant-menu {
  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: $active-background !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .ant-layout-sider.ant-layout-sider-dark {
    max-width: 290px !important;
    width: 290px !important;
  }
}
