@import "/public/assets/style/variables";

.dashboard-page-card {
  padding: 10px 24px;
  width: 330px;
  height: 130px;
  position: relative;
  background: $app-color 0 0 no-repeat padding-box;
  box-shadow: 4px 4px 12px #0000001c;
  border-radius: 10px;
  opacity: 1;
  margin: 7px;
  background: black;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
